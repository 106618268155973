import React from 'react'
import Img from 'gatsby-image'
import * as styles from './thumbnail.module.css'

const Thumbnail = ({ thumbnail }) => {
  return (
    <li key={thumbnail?.id}>
      <a href={thumbnail?.link} className={styles.link} target="_blank" rel="noopener noreferrer">
        <figure className={styles.figure}>
          <Img fluid={thumbnail?.image?.fluid} alt={thumbnail?.title} className={styles.image}/>
          <figcaption className={styles.caption}>
            {thumbnail?.title}
          </figcaption>
        </figure>
      </a>
    </li>
  )
}

export default Thumbnail
