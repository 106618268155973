import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PlayMe from '../../icons/play-me.svg'
import * as styles from './mega-thumbnail.module.css'

const MegaThumbnail = ({ thumbnail }) => {
  return (
    <li key={thumbnail?.id} className={styles.megaThumbnail}>
      <a href={thumbnail?.link} className={styles.link} target="_blank" rel="noopener noreferrer">
        <figure className={styles.figure}>
          <PlayMe />
          <Img fluid={thumbnail?.image?.fluid} alt={thumbnail?.title} className={styles.image}/>
          <figcaption className={styles.caption}>
            {thumbnail?.title}
            <Link className={styles.linkUnderline} to="/placements">VIEW ALL PLACEMENTS</Link>
          </figcaption>
        </figure>
      </a>
    </li>
  )
}

export default MegaThumbnail
