import React from 'react'
import ArtistGrid from '../artist-grid'
import Artist from '../artist'
import { Link } from 'gatsby'
import * as styles from './alumni-artists.module.css'

const AlumniArtists = ({ artists }) => {
  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <h2>alumni artists</h2>
        <Link to="/artists">view all</Link>
      </div>
      <ArtistGrid>
        {artists?.map(({ node }) => <Artist artist={node}/>)}
      </ArtistGrid>
    </section>
  )
}

export default AlumniArtists
