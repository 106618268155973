import React from 'react'
import { Link } from 'gatsby'
import PartnerGrid from '../partner-grid'
import Partner from '../partner'
import * as styles from './label-partners.module.css'

const LabelPartners = ({ partners }) => {
  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <h2>label partners</h2>
        <Link to="/artists">view all</Link>
      </div>
      <PartnerGrid>
        {partners?.map(({ node }) => <Partner partner={node}/>)}
      </PartnerGrid>
    </section>
  )
}

export default LabelPartners
