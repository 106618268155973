import React from 'react'
// import Img from 'gatsby-image'

const Partner = ({ partner }) => {
  return (
    <li>
      <a href={partner.link} target="_blank" rel="noopener noreferrer">
        {/* <Img alt={partner.title} fluid={partner.image.fluid} /> */}
        <img src={partner.image.fluid.src} alt={partner.title} />
      </a>
    </li>
  )
}

export default Partner
