import React from 'react'
import Img from 'gatsby-image'
import * as styles from './artist.module.css' 

const Artist = ({ artist }) => {
  return (
    <li key={artist?.title} id={artist?.title}>
      <a href={artist?.link} className={styles.link} target="_blank" rel="noopener noreferrer">
        <figure className={styles.figure}>
          <Img fluid={artist?.image?.fluid} alt={artist?.title} className={styles.image} />
          <figcaption className={styles.caption}>{artist?.title}</figcaption>
        </figure>
      </a>
    </li>
  )
}

export default Artist
