import React from 'react'
import ArtistGrid from '../artist-grid'
import Artist from '../artist'
import { Link } from 'gatsby'
import * as styles from './featured-artists.module.css'

const FeaturedArtists = ({ artists }) => {
  return (
    <section>
      <div className={styles.header}>
        <h2>featured artists</h2>
        <Link to="/artists">view all</Link>
      </div>
      <ArtistGrid>
        {artists?.map(({ node }) => <Artist artist={node} />)}
      </ArtistGrid>
      <Link to="/artists" className={styles.link}>load more</Link>
    </section>
  )
}

export default FeaturedArtists
