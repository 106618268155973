import React from 'react'
import * as styles from './news.module.css'

const News = ({ newsLinks }) => {
  return (
    <section className={styles.news_section}>
      <h2>Latest News</h2>
      <ul>
      {newsLinks?.map(newslink => (
          <li>
            <a target="_blank" href={newslink.node.url}>
              <figure className="image">
                <img src={newslink.node.thumbnailImage.file.url} alt="" />
              </figure>
              <aside className="text">
                <p>{ newslink.node.headline.headline }</p>
              </aside>
              <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 323.5 323">
                <g>
                  <path
                    d="M14.2,29.4C6.2,29.4,0,35.5,0,43.6v265.2c0,8.1,6.2,14.2,14.2,14.2h265.2c8.1,0,14.2-6.2,14.2-14.2V157.2h-28.4v137.3H28.4
                        V57.8h137.3V29.4C165.8,29.8,14.2,29.8,14.2,29.4L14.2,29.4z"
                  />
                  <path d="M164.3,178.5L295,48.3v52.6h28.4V0H222.1v28.4h52.6L144.4,158.6L164.3,178.5z" />
                </g>
              </svg>
            </a>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default News
