import '../pages/index.css'
import React from 'react'
import { graphql } from 'gatsby'
import { get, shuffle } from 'lodash'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import IndependentSyncLicensing from '../components/independent-sync-licensing'
import FeaturedArtists from '../components/featured-artists'
import Break from '../components/break'
import News from '../components/news'
import AlumniArtists from '../components/alumni-artists'
//import BreakMobile from '../components/break-mobile'
import LabelPartners from '../components/label-partners'
import LabelAlumni from '../components/label-alumni'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [megaThumbnail] = get(this, 'props.data.allContentfulHeroVideo.edges')
    const regularThumbnails = get(this, 'props.data.allContentfulVideo.edges')
    const allThumbnails = [megaThumbnail, ...regularThumbnails]
    const artists = get(this, 'props.data.featuredArtists.edges')
    const alumniArtists = get(this, 'props.data.alumniArtists.edges')
    const partners = get(this, 'props.data.partnerLabels.edges')
    const labelAlumniPartners = get(this, 'props.data.alumniLabels.edges')
    const allNewsLinks = get(this, 'props.data.allContentfulNewsLink.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle} />
        <IndependentSyncLicensing thumbnails={allThumbnails} />
        <News newsLinks={allNewsLinks} />
        <FeaturedArtists artists={artists} />
        <AlumniArtists artists={alumniArtists} />
        <LabelPartners partners={partners} />
        <LabelAlumni partners={labelAlumniPartners} />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulHeroVideo {
      edges {
        node {
          link
          title
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          playIcon {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulVideo {
      edges {
        node {
          link
          title
          image {
            fluid(maxHeight: 323) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulMission {
      edges {
        node {
          statement {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    featuredArtists: allContentfulArtist(filter: { tag: { eq: "Featured" } }) {
      edges {
        node {
          link
          title
          tag
          image {
            fluid(maxWidth: 184, maxHeight: 184) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    alumniArtists: allContentfulArtist(filter: { tag: { eq: "Alumni" } }) {
      edges {
        node {
          link
          title
          tag
          image {
            fluid(maxWidth: 184, maxHeight: 184) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    partnerLabels: allContentfulLabel(filter: { tag: { eq: "Partners" } }, sort: { fields: title, order: ASC }) {
      edges {
        node {
          link
          image {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    alumniLabels: allContentfulLabel(filter: { tag: { eq: "Alumni" } }, sort: { fields: title, order: ASC }) {
      edges {
        node {
          link
          image {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulNewsLink {
      edges {
        node {
          headline {
            headline
          }
          thumbnailImage {
            file {
              url
            }
          }
          url
        }
      }
    }
  }
`
