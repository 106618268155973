import React from 'react'
import Thumbnail from '../thumbnail'
import Gallery from '../gallery'
import * as styles from './independent-sync-licensing.module.css'
import MegaThumbnail from '../mega-thumbnail'

const IndependentSyncLicensing = ({ thumbnails }) => {
  return (
    <section className={styles.independentSyncLicensing}>
      <h1 className={styles.heading}><strong>Independent Sync Licensing</strong></h1>
      <p className={styles.description}>
        There’s a duality to the meaning of Greater Goods and it’s more of a mantra than a name. For us it means that we’re focused on providing opportunities for independent artists to keep doing what they’re doing. It’s also a sign that though there’s lots of good music out there, we’re zeroing in on what’s great.
      </p>
      <Gallery>
        {thumbnails?.map(({ node }, idx) =>
          (idx === 0) ? <MegaThumbnail thumbnail={node}/> : <Thumbnail thumbnail={node}/>
        )}
      </Gallery>
    </section>
  )
}

export default IndependentSyncLicensing
