import React from 'react'
import * as styles from './label-alumni-grid.module.css'

const LabelAlumniGrid = ({ children }) => {
  return (
    <ul className={styles.grid}>
      {children}
    </ul>
  )
}

export default LabelAlumniGrid
