import React from 'react'
import * as styles from './gallery.module.css'

const Gallery = ({ children }) => {
  return (
    <ul className={styles.grid}>
      {children}
    </ul>
  )
}

export default Gallery
