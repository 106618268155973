import React from 'react'
import LabelAlumniGrid from '../label-alumni-grid'
import Partner from '../partner'
import * as styles from './label-alumni.module.css'

const LabelAlumni = ({ partners }) => {
  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <h2>label alumni</h2>
      </div>
      <LabelAlumniGrid>
        {partners?.map(({ node }) => <Partner partner={node}/>)}
      </LabelAlumniGrid>
    </section>
  )
}

export default LabelAlumni
